import React from "react";
import Marquee from "react-fast-marquee";
import SkillsData from "../../assets/data/skills";

const Skills = () => {
  const getMarqueeWidth = () => {
    if (window.innerWidth <= 600) return "16.5rem";
    if (window.innerWidth <= 800) return "30.5rem";
    if (window.innerWidth <= 992) return "47.9rem";
    return "58.2rem";
  };
  console.log(getMarqueeWidth());
  console.log(window.innerWidth);
  return (
    <section className="skills">
      <div className="row">
        <div className="col-md">
          <h2 className="section-title">Skills & Tools</h2>
          <div className="skillsContainer">
            <div className="skill">
              <Marquee
                style={{ width: getMarqueeWidth() }}
                pauseOnHover={true}
                gradient={false}
                speed={120}
                play={true}
              >
                {Object.values(SkillsData).map((skill, id) => (
                  <div className="skill--box" key={id}>
                    <img src={skill.logo} alt={skill.name} />
                    <h3>{skill.name}</h3>
                  </div>
                ))}
              </Marquee>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Skills;
