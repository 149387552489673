import React, { useRef } from "react";
import { ContactSvg } from "../../assets/images/icon";
import Config from "../../assets/data/config";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Contact = () => {
  const formRef = useRef<HTMLFormElement>(null);

  const onSubmit = async (event: any) => {
    event.preventDefault();
    const formData = new FormData(event.target);

    const fields = ["first_name", "last_name", "mail", "message"];
    for (const field of fields) {
      if (!formData.get(field)) {
        toast.error("All fields are required!");
        return;
      }
    }

    formData.append("access_key", `${Config.contact_key}`);

    const object = Object.fromEntries(formData);
    const json = JSON.stringify(object);

    const res = await fetch("https://api.web3forms.com/submit", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: json,
    }).then((res) => res.json());

    if (res.success) {
      toast.success("Message sent successfully!");
      if (formRef.current) {
        formRef.current.reset();
      }
    } else {
      toast.error("There was an error sending your message.");
    }
  };

  return (
    <div className="col-10 col-md-11 col-lg-9">
      <div className="main-bar">
        <section className="contact">
          <div className="row">
            <div className="contact-title">
              <h2>Contact</h2>
              <p>
                Please the fill the form and you team will reach you in 24 hours
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-lg-6">
              <div className="contact-map">
                <img src={ContactSvg} alt="contact" />
              </div>
            </div>
            <div className="col-12 col-lg-6">
              <div className="contact-form">
                <form onSubmit={onSubmit} ref={formRef}>
                  <input
                    type="text"
                    name="first_name"
                    placeholder="First Name"
                  />
                  <input type="text" name="last_name" placeholder="Last Name" />
                  <input type="email" name="mail" placeholder="Email" />
                  <textarea
                    name="message"
                    cols={30}
                    rows={10}
                    placeholder="Write us a message"
                  ></textarea>
                  <div className="contact-form-btn">
                    <button className="btn_primary">Send Message</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      </div>
      <ToastContainer />
    </div>
  );
};

export default Contact;
