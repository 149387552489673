import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Me } from "../../assets/images/images";
import config from "../../assets/data/config";
import api from "../../services/api";

const Sidebar = () => {
  const [Profile, setProfile] = useState("");
  useEffect(() => {
    api.get(`/users/${config.github_username}`).then((res) => {
      setProfile(res.data.avatar_url);
    });
  });

  const toggleDarkMode = () => {
    let local_storage_mode = localStorage.getItem("mode");

    if (local_storage_mode === "dark") {
      localStorage.setItem("mode", "light");
      document.body.classList.remove("dark-theme");
    } else {
      localStorage.setItem("mode", "dark");
      document.body.classList.add("dark-theme");
    }

    updateModeIcon();
  };

  const updateModeIcon = () => {
    const moonSvg = document.getElementById("moon-svg");
    const sunSvg = document.getElementById("sun-svg");
    const modeChanger = document.getElementById("mode-changer");

    if (moonSvg && sunSvg && modeChanger) {
      if (document.body.classList.contains("dark-theme")) {
        moonSvg.style.display = "none";
        sunSvg.style.display = "block";
        modeChanger.style.background = "#434343";
      } else {
        sunSvg.style.display = "none";
        moonSvg.style.display = "block";
        modeChanger.style.background = "#EDEDED";
      }
    }
  };

  useEffect(() => {
    let local_storage_mode = localStorage.getItem("mode");
    if (local_storage_mode === "dark") {
      document.body.classList.add("dark-theme");
    } else {
      document.body.classList.remove("dark-theme");
    }
    updateModeIcon();
  }, []);

  return (
    <div className="col-2 col-md-1 col-lg-3">
      <header className="sidebar">
        <div className="sidebar-profile">
          <div className="sidebar-profile__image">
            <img src={Profile} alt="Hesam-gh" />
            <button
              id="mode-changer"
              className="mode-changer"
              onClick={toggleDarkMode}
            >
              <svg
                id="moon-svg"
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12.6179 12.071C13.7745 12.3738 14.974 12.4512 16.1519 12.3038C15.7564 13.1576 15.2095 13.9368 14.5344 14.6022C13.5545 15.568 12.3386 16.2603 11.0079 16.61C9.67716 16.9596 8.27805 16.9546 6.9499 16.5953C5.62174 16.2359 4.41091 15.5349 3.438 14.562C2.4651 13.5891 1.76407 12.3783 1.40475 11.0501C1.04543 9.72196 1.04036 8.32284 1.39004 6.99212C1.73973 5.66139 2.43196 4.44551 3.39779 3.46558C4.06316 2.79049 4.84238 2.24362 5.69619 1.84807C5.54883 3.02598 5.62624 4.22554 5.92898 5.38214C6.34993 6.99039 7.19137 8.45761 8.36688 9.63312C9.54239 10.8086 11.0096 11.6501 12.6179 12.071Z"
                  stroke="#707070"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <svg
                id="sun-svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12 17.625C15.1066 17.625 17.625 15.1066 17.625 12C17.625 8.8934 15.1066 6.375 12 6.375C8.8934 6.375 6.375 8.8934 6.375 12C6.375 15.1066 8.8934 17.625 12 17.625Z"
                  stroke="#F7F7F7"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M12 3.375V1.5"
                  stroke="#F7F7F7"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M5.89689 5.89707L4.57501 4.5752"
                  stroke="#F7F7F7"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M3.375 12H1.5"
                  stroke="#F7F7F7"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M5.89689 18.103L4.57501 19.4249"
                  stroke="#F7F7F7"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M12 20.625V22.5"
                  stroke="#F7F7F7"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M18.1031 18.103L19.425 19.4249"
                  stroke="#F7F7F7"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M20.625 12H22.5"
                  stroke="#F7F7F7"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M18.1031 5.89707L19.425 4.5752"
                  stroke="#F7F7F7"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
          </div>
          <h2 className="sidebar-profile__name">Hesam Gh</h2>
          <p className="sidebar-profile__designation">Back-end Developer</p>
          <div className="sidebar-profile__social-media">
            <ul>
              <li>
                <a className="Link_sidebar" href={config.social_media.github}>
                  <i className="fa-brands fa-github"></i>
                </a>
              </li>
              <li>
                <a className="Link_sidebar" href={config.social_media.telegram}>
                  <i className="fa-brands fa-telegram"></i>
                </a>
              </li>
              <li>
                <a className="Link_sidebar" href={config.social_media.discord}>
                  <i className="fa-brands fa-discord"></i>
                </a>
              </li>
              <li>
                <a className="Link_sidebar" href={config.social_media.twitter}>
                  <i className="fa-brands fa-twitter"></i>
                </a>
              </li>
            </ul>
          </div>
        </div>
        <nav className="sidebar-menu">
          <ul>
            <li className="menu-item">
              <Link className="Link_sidebar" to={"/"}>
                <i className="fa-solid fa-house"></i>
                <span>Home</span>
              </Link>
            </li>
            <li className="menu-item">
              <Link className="Link_sidebar" to={"/resume"}>
                <i className="fa-solid fa-id-card-clip"></i>
                <span>Resume</span>
              </Link>
            </li>
            <li className="menu-item">
              <Link className="Link_sidebar" to={"/portfolio"}>
                <i className="fa-solid fa-briefcase"></i>
                <span>Portfolio</span>
              </Link>
            </li>

            <li className="menu-item">
              <Link className="Link_sidebar" to={"/contact"}>
                <i className="fa-solid fa-table-list"></i>
                <span>Contact</span>
              </Link>
            </li>
          </ul>
        </nav>
        <div className="sidebar-contact">
          <p className="sidebar-contact__address">
            <i className="fa-solid fa-location-arrow"></i>
            <span style={{ fontSize: "14px", fontStyle: "italic" }}>
              Iran, Tehran
            </span>
          </p>
          <p className="sidebar-contact__email">
            <i className="fa-regular fa-envelope"></i>
            <span style={{ fontSize: "14px", fontStyle: "italic" }}>
              {config.email}
            </span>
          </p>
        </div>
      </header>
    </div>
  );
};

export default Sidebar;
