const config = {
  email: "contact@hesam-gh.site",
  github_username: "its-hesam",
  contact_key: "e40283e9-166f-43fc-951d-0d6948499e07",
  social_media: {
    github: "https://github.com/its-hesam",
    telegram: "https://t.me/Hesam_0G",
    discord: "https://discord.com/users/",
    twitter: "https://x.com/Real_Hesam",
  },
};

export default config;
