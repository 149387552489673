import React, { useEffect, useRef, useState } from "react";
import { TestimonialsSvg } from "../../assets/images/icon";
import testimonials from "../../assets/data/testimonials";

const Testimonials = () => {
  const slidesImageRef = useRef<HTMLDivElement[]>([]);
  const slidesTextRef = useRef<HTMLDivElement[]>([]);
  const [slideIndex, setSlideIndex] = useState(1);

  useEffect(() => {
    const plusSlides = (n: number) => {
      setSlideIndex((prev) => prev + n);
      showSlides(slideIndex + n);
    };
    const handleNextClick = () => {
      plusSlides(1);
    };
    const handlePrevClick = () => {
      plusSlides(-1);
    };

    showSlides(slideIndex);

    const prevButton = document.querySelector(".prev");
    const nextButton = document.querySelector(".next");
    if (prevButton && nextButton) {
      prevButton.addEventListener("click", handlePrevClick);
      nextButton.addEventListener("click", handleNextClick);
    }

    return () => {
      if (prevButton && nextButton) {
        prevButton.removeEventListener("click", handlePrevClick);
        nextButton.removeEventListener("click", handleNextClick);
      }
    };
  }, [slideIndex]);

  const showSlides = (index: number) => {
    let i;
    const slidesImage = slidesImageRef.current;
    const slidesText = slidesTextRef.current;

    let newIndex =
      index > slidesImage.length ? 1 : index < 1 ? slidesImage.length : index;

    for (i = 0; i < slidesImage.length; i++) {
      slidesImage[i].style.display = "none";
      slidesText[i].style.display = "none";
    }

    if (slidesImage.length > 0) {
      slidesImage[newIndex - 1].style.display = "block";
      slidesText[newIndex - 1].style.display = "block";
    }

    setSlideIndex(newIndex);
  };

  return (
    <section className="clients section-padding">
      <div className="row">
        <div className="section-title pb-5">
          <h2>Testimonials</h2>
        </div>
      </div>
      <div className="row">
        <div className="col-12 col-md-6 col-xl-6">
          <div className="testimonial">
            <svg
              className="qoute-img"
              width="48"
              height="38"
              viewBox="0 0 48 38"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clipPath="url(#clip0_111_2156)">
                <path
                  d="M27.5979 38V28.3041C29.8048 28.3041 31.4678 27.9247 32.5869 27.1659C33.7321 26.3363 34.4352 25.3963 34.696 24.3462C34.9569 23.296 35.1343 21.1956 35.2282 18.045H27.5979V0H48V15.3906C48 20.9645 47.837 24.757 47.5109 26.7682C47.1848 28.7793 46.23 30.8088 44.6466 32.8567C43.154 34.7397 41.1524 36.1483 38.8829 36.9131C36.676 37.6377 32.9143 38 27.5979 38ZM0 38V28.3041C2.1782 28.3041 3.82685 27.9247 4.94595 27.1659C5.99659 26.5355 6.7711 25.528 7.11372 24.3462C7.41372 23.2566 7.57675 21.1562 7.60284 18.045H0V0H20.3473V15.3906C20.3473 20.9645 20.1842 24.757 19.8582 26.7682C19.5321 28.7793 18.5786 30.8088 16.9978 32.8567C15.5044 34.7406 13.5013 36.1494 11.2301 36.9131C9.03106 37.6377 5.28768 38 0 38Z"
                  fill="#161616"
                />
              </g>
              <defs>
                <clipPath id="clip0_111_2156">
                  <rect width="48" height="38" fill="white" />
                </clipPath>
              </defs>
            </svg>

            {testimonials.map((testimonial, index) => (
              <div
                key={index}
                className="mySlideText"
                ref={(el) => (slidesTextRef.current[index] = el!)}
                style={{ display: index === 0 ? "block" : "none" }}
              >
                <p className="testimonial__qoute-text">{testimonial.text}</p>
              </div>
            ))}
            <div className="testimonial__client-info">
              {testimonials.map((testimonial, index) => (
                <div
                  key={index}
                  className="mySlide"
                  ref={(el) => (slidesImageRef.current[index] = el!)}
                  style={{ display: index === 0 ? "block" : "none" }}
                >
                  <div className="client">
                    <div className="client__image">
                      <img src={testimonial.image} alt={testimonial.name} />
                    </div>
                    <div className="client__info">
                      <h2 className="client__info-name">{testimonial.name}</h2>
                      <p className="client__info-designation">
                        {testimonial.post}
                      </p>
                    </div>
                  </div>
                </div>
              ))}
              <div className="slider-next-prev-btn">
                <p className="prev">❮</p>
                <p className="next">❯</p>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 col-md-6 col-xl-6">
          <div className="clients-gallery">
            <img src={TestimonialsSvg} alt="testimonials" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Testimonials;
