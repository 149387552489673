import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Api from "../../services/api";
import Config from "../../assets/data/config";
import { Github } from "../../assets/images/images";

const Portfolio = () => {
  const [repos, setRepos] = useState([]);
  const [orgs, setOrgs] = useState([]);
  const [activeTab, setActiveTab] = useState("repos");

  useEffect(() => {
    loadPosts();
  }, []);

  const loadPosts = () => {
    Api.get("/users/its-hesam/repos")
      .then((res) => res.data)
      .then((data) => {
        setRepos(data.slice(0, 6));
      });
  };

  const handleTabClick = (category: any) => {
    setActiveTab(category);
    if (category === "orgs") {
      Api.get(`/users/${Config.github_username}/orgs`)
        .then((res) => res.data)
        .then((data) => {
          setOrgs(data);
        });
    } else if (category === "repos") {
      Api.get(`/users/${Config.github_username}/repos`)
        .then((res) => res.data)
        .then((data) => {
          setRepos(data);
        });
    }
  };

  const renderRepos = () => {
    return repos.map((post: any) => (
      <div className="portfolio-card" key={post.id}>
        <div className="portfolio-card__feature-image">
          <Link to={post.html_url}>
            <img src={Github} alt={post.name} />
          </Link>
        </div>
        <h2 className="portfolio-card__title">
          <Link className="Link_sidebar" target="_blank" to={post.html_url}>
            {post.name}
          </Link>
        </h2>
        <Link
          className="Link_sidebar portfolio-card__btn"
          target="_blank"
          to={post.html_url}
        >
          View Project <i className="fa-solid fa-arrow-right"></i>
        </Link>
      </div>
    ));
  };

  const renderOrgs = () => {
    return orgs.map((post: any) => (
      <div className="portfolio-card" key={post.id}>
        <div className="portfolio-card__feature-image">
          <Link
            to={`https://github.com/${post.login}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={post.avatar_url} alt={post.login} />
          </Link>
        </div>
        <div className="portfolio-card__meta">
          <p>{post.description}</p>
        </div>
        <h2 className="portfolio-card__title Link_sidebar">
          <Link
            className="Link_sidebar"
            to={`https://github.com/${post.login}`}
          >
            {post.login}
          </Link>
        </h2>
        <Link
          className="portfolio-card__btn Link_sidebar"
          to={`https://github.com/${post.login}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          View Organization <i className="fa-solid fa-arrow-right"></i>
        </Link>
      </div>
    ));
  };

  return (
    <div className="col-lg-9 col-md-11 col-10">
      <div className="main-bar">
        <div className="title-tab-wrapper">
          <h2 className="page-title">Portfolio</h2>
          <ul className="tab" id="portfolio-tab">
            <li>
              <a className="tab-item" onClick={() => handleTabClick("repos")}>
                Repository
              </a>
            </li>
            <li>
              <a className="tab-item" onClick={() => handleTabClick("orgs")}>
                Organizations
              </a>
            </li>
          </ul>
        </div>
        <div id="portfolio">
          {activeTab === "repos" && renderRepos()}
          {activeTab === "orgs" && renderOrgs()}
        </div>
      </div>
    </div>
  );
};

export default Portfolio;
