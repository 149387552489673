import React from "react";
import "./App.css";
import { Route, Routes } from "react-router-dom";
import { Home, Portfolio, Contact, Resume, Notfound } from "./pages";
import { Sidebar } from "./components";
import "bootstrap/dist/css/bootstrap.min.css";

function App() {
  return (
    <div>
      <link
        rel="stylesheet"
        href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0/css/all.min.css"
      />
      <main className="main-wrapper">
        <div className="container-xxl">
          <div className="row">
            <Sidebar />
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/resume" element={<Resume />} />
              <Route path="/portfolio" element={<Portfolio />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="*" element={<Notfound />} />
            </Routes>
          </div>
        </div>
      </main>
    </div>
  );
}

export default App;
