import React from "react";
import { Link } from "react-router-dom";
import { Testimonials } from "../../components";
import {
  Click,
  Robot,
  Website,
  Assistant,
  About,
} from "../../assets/images/icon";
import { LogoBg } from "../../assets/images/images";
import config from "../../assets/data/config";

const Home = () => {
  return (
    <div className="col-10 col-md-11 col-lg-9">
      <main className="main-bar">
        <section className="hero section-padding">
          <div className="row">
            <div className="col-12 col-md-6 col-lg-6 col-xl-6">
              <div className="hero-content">
                <h1 className="hero-content__title">
                  Hi! I’m
                  <span className="hero-content__title--name"> Hesam</span>
                </h1>
                <p>Back-end Developer</p>
                <div className="hero-content__btn">
                  <Link to={`mailto:` + config.email}>
                    <button className="click__btn btn__hire">
                      <span>
                        <img src={Click} alt="" />
                      </span>
                      <span>Hire me </span>
                    </button>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-6 col-xl-6">
              <div className="hero-img">
                <img src={LogoBg} alt="hero" />
              </div>
            </div>
          </div>
        </section>
        <section className="about section-padding">
          <div className="row">
            <div className="col-12 col-md-6 col-xl-6">
              <div className="about-img">
                <img src={About} alt="about-me" />
              </div>
            </div>
            <div className="col-12 col-md-6 col-xl-6">
              <div className="section-title">
                <h2>About me</h2>
              </div>
              <div className="about-content">
                <p className="mb-4">
                  My name is Hesam, and I have been immersed in the world of
                  technology and programming for nearly four years. From a young
                  age, I've had a profound passion for computers and coding,
                  always eager to explore and create. This enthusiasm has driven
                  me to constantly seek out new projects, embrace challenging
                  problems, and strive for excellence in every task. I am
                  meticulous in my work, aiming to deliver solutions that are as
                  flawless as possible.
                </p>

                <p>
                  I thrive on innovation and enjoy pushing the boundaries of
                  what's possible. Whether it's tackling complex challenges or
                  diving into the latest tech trends, I am committed to
                  continuous learning and improvement. My dedication to
                  precision and my drive to achieve the best results make me a
                  reliable and highly motivated professional in the tech
                  industry.
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className="service section-padding">
          <div className="row">
            <div className="col-12">
              <div className="section-title">
                <h2>What I’m doing?</h2>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-md-6 mb-4 mb-md-0 col-xl-4">
              <div className="service-card">
                <div className="service-card-icon service-card-icon--graphic">
                  <img src={Robot} alt="robot" />
                </div>
                <div className="service-card-content">
                  <h3>Bot Development</h3>
                  <p>
                    Crafting intelligent and responsive bots for Discord and
                    Telegram to enhance user engagement and automate tasks.
                  </p>
                  <div className="service-card-content-btn">
                    <Link
                      to={`mailto:` + config.email}
                      className="hire-btn btn_primary Link_sidebar"
                    >
                      Hire me <i className="fa-solid fa-arrow-right"></i>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6 mb-4 mb-md-0 col-xl-4">
              <div className="service-card">
                <div className="service-card-icon service-card-icon--arch">
                  <img src={Website} alt="Website" />
                </div>
                <div className="service-card-content">
                  <h3>Website Design</h3>
                  <p>
                    Creating visually appealing and user-friendly websites that
                    deliver seamless experiences across all devices.
                  </p>
                  <div className="service-card-content-btn">
                    <Link
                      to={`mailto:` + config.email}
                      className="hire-btn btn_primary Link_sidebar"
                    >
                      Hire me <i className="fa-solid fa-arrow-right"></i>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6 col-xl-4">
              <div className="service-card">
                <div className="service-card-icon service-card-icon--ux">
                  <img src={Assistant} alt="assistant" />
                </div>
                <div className="service-card-content">
                  <h3>Collaboration</h3>
                  <p>
                    Collaborating on innovative projects and generating creative
                    ideas to bring visions to life and achieve project goals.
                  </p>
                  <div className="service-card-content-btn">
                    <Link
                      to={`mailto:` + config.email}
                      className="hire-btn btn_primary Link_sidebar"
                    >
                      Hire me <i className="fa-solid fa-arrow-right"></i>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="service-history">
            <div className="service-history-card">
              <h2 className="complete">60</h2>
              <p>Project Complete</p>
            </div>
            <div className="service-history-card">
              <h2 className="years">4</h2>
              <p>Years of Experience</p>
            </div>
            <div className="service-history-card">
              <h2 className="clients">20</h2>
              <p>Happy Clients</p>
            </div>
          </div>
        </section>
        <Testimonials />
      </main>
    </div>
  );
};

export default Home;
