import {
  Typescript,
  Javascript,
  Nodejs,
  Html,
  Css,
  Tailwind,
  Expressjs,
  React,
  Mongodb,
  Git,
  Ubuntu,
  Telegraf,
  Bootstrap,
  Nginx,
  Npm,
} from "../images/icon";

const skills = {
  1: {
    name: "Node JS",
    logo: Nodejs,
  },
  2: {
    name: "HTML",
    logo: Html,
  },
  3: {
    name: "CSS",
    logo: Css,
  },
  4: {
    name: "Tailwind",
    logo: Tailwind,
  },
  5: {
    name: "Express.js",
    logo: Expressjs,
  },
  6: {
    name: "Javascript",
    logo: Javascript,
  },
  7: {
    name: "Typescript",
    logo: Typescript,
  },
  8: {
    name: "React",
    logo: React,
  },
  9: {
    name: "MongoDB",
    logo: Mongodb,
  },
  10: {
    name: "Git",
    logo: Git,
  },
  11: {
    name: "Ubuntu",
    logo: Ubuntu,
  },
  12: {
    name: "Telegraf",
    logo: Telegraf,
  },
  13: {
    name: "Bootstrap",
    logo: Bootstrap,
  },
  14: {
    name: "Nginx",
    logo: Nginx,
  },
  15: {
    name: "Npm",
    logo: Npm,
  },
};

export default skills;
