import React from "react";
import styles from "./notfound.module.scss";
const Notfound = () => {
  return (
    <div className="col-lg-9 col-md-11 col-10">
      <div className="main-bar">
        <h1 className={styles.center_text}>Page Not Found 404</h1>
      </div>
    </div>
  );
};

export default Notfound;
