import React from "react";
import { Skills } from "../../components";
import { Easyload, Hagh } from "../../assets/images/images";

const Resume = () => {
  return (
    <div className="col-10 col-md-11 col-lg-9">
      <div className="main-bar">
        <section className="education">
          <div className="row mb-5">
            <div className="col-12">
              <div className="education-top">
                <h2 className="section-title">Experiences and skills</h2>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-12 col-md-5">
              <div className="experience-card">
                <div className="experience-card__left">
                  <img src={Hagh} alt="HAGH-BOT(Discord)" />
                </div>
                <div className="experience-card__right">
                  <h2>HAGH Bot</h2>
                  <h3>2019-Present</h3>
                  <p>
                    A fun Discord bot that supports both Persian and English
                    languages, bringing joy and entertainment to your server.
                  </p>
                </div>
              </div>
              <div className="experience-card">
                <div className="experience-card__left">
                  <img src={Easyload} alt="EasyLoad(Telegram)" />
                </div>
                <div className="experience-card__right">
                  <h2>EasyLoad Bot</h2>
                  <h3>2023-Present</h3>
                  <p>
                    A Telegram bot that makes downloading from Instagram easier
                    and more convenient with a new and user-friendly method.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-7">
              <div className="institution">
                <div className="institution-info">
                  <h3>Collaborations on Major Projects</h3>
                  <p className="details">
                    I have collaborated on significant projects within various
                    communities and had the honor of working with renowned
                    individuals. These experiences have enriched my skills and
                    broadened my perspective, allowing me to contribute
                    effectively to diverse and impactful initiatives. If you
                    need any further adjustments or additions, feel free to let
                    me know!
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <Skills />
      </div>
    </div>
  );
};

export default Resume;
