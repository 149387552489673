import { ParsaFiroozi } from "../images/images";
export interface Testimonial {
  name: string;
  image: string;
  text: string;
  post: string;
}

const testimonials: Testimonial[] = [
  {
    name: "Parsa Firoozi",
    image: ParsaFiroozi,
    text: "Hesam is one of the programmers who has a very good ability in problem solving and is very interested in learning and gaining experience, besides, he has great skills in developing bots.",
    post: "CEO | Developer",
  },
  {
    name: "Test1",
    image: "",
    text: "Test tex1",
    post: "Test 1",
  },
  {
    name: "Test2",
    image: "",
    text: "Test tex2",
    post: "Test 2",
  },
];

export default testimonials;
